<template>
  <div ref="atomDropdownMenu" v-outside="handleClose" class="position-relative">
    <slot name="trigger" v-bind="{ handleClick: handleTriggerClick, menuVisible: visible }" />
    <div
      class="atom-dropdown-menu-collapsed mt-8"
      :class="{ 'd-block': visible, 'd-none': !visible }"
      :style="atomDropdownMenuStyles"
    >
      <div v-for="(option, key) in options" :key="option.id">
        <component
          :is="option.to ? 'a' : 'div'"
          :href="option.to"
          class="atom-dropdown-menu-item p-16 d-flex align-items-center gap-8 paragraph-x-small text-decoration-none text-base-black"
          :class="{ 'border-bottom': key < options.length - 1, active: option.id === locale }"
          @click="handleLinkClick(option)"
        >
          <component
            :is="option.icon"
            v-if="option.icon"
            viewBox="0 0 24 24"
            class="color-main-red"
          />
          <component :is="option.component" v-else-if="option.component" class="custom-img" />
          <span class="title paragraph-small">{{ option.title }}</span>
        </component>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Emits } from '~common/enums'
import type {
  AtomDropdownMenuEmits,
  AtomDropdownMenuItemProps,
  AtomDropdownMenuProps,
} from '~common/types'

defineProps<AtomDropdownMenuProps>()
const emits = defineEmits<AtomDropdownMenuEmits>()

const { locale } = useI18n()

const visible = ref(false)
const atomDropdownMenu = ref<HTMLDivElement | null>(null)
const atomDropdownMenuStyles = ref({})

const handleClose = () => {
  visible.value = false
}
const handleTriggerClick = () => {
  visible.value = !visible.value
}
const handleLinkClick = (option: AtomDropdownMenuItemProps) => {
  emits(Emits.SELECT_OPTION, option)
}

const setMenuMaxHeight = () => {
  const menu = atomDropdownMenu.value
  if (!menu) {
    return
  }

  const { height, y } = menu.getBoundingClientRect()

  atomDropdownMenuStyles.value = {
    maxHeight: `${window.innerHeight - height - y - 20}px`,
    overflow: 'auto',
  }
}

onMounted(() => {
  setMenuMaxHeight()
})
</script>

<style lang="scss" scoped>
.atom-dropdown-menu-collapsed {
  position: absolute;
  right: 0;
  background-color: $base-white;
  min-width: 192px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 11;
}

.atom-dropdown-menu-item {
  cursor: pointer;
}

.atom-dropdown-menu-item:hover,
.atom-dropdown-menu-item.active {
  background: $grey-50-hover;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-img {
  height: $space-12;
}
</style>
